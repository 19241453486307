import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import iconPlayer from '../img/icon-player.svg';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    getInTouchHeading,
    getInTouchDesc,
    addressTitleColumn1,
    addressMap,
    addressMapLink,
    contactNumbersTitleColumn2,
    contactNumbers,
    contactNumbersLink,
    emailTitleColumn3,
    emailAddress,
    emailAddLink,
    headerImage,
    historyImage,
    historyHeading,
    description,
    whyHeading,
    whyText,
    whyWeExistImage,
    title,
    vision,
    visionHeading,
    visionImage,
    mission,
    missionImage,
    history,
    accreditation,
    accreditationHeading,
    accreditedImage
  } = data.allContentfulPageAbout.edges[0].node;
  const {
    noOfMoms,
    noOfBabiesText,
    noOfMomsText,
    noOfOutreaches,
    noOfOutreachesText,
    description: statisticsDesc,
    heading,
    noOfBabies,
    noOfHealthBibleTeachingParticipantsText,
    noOfHealthBibleTeachingParticipants,
    noOfPrenatalCheckupsText,
    noOfPrenatalCheckups,
    noOfHivScreeningsText,
    noOfHivScreenings
  } = data.allContentfulSectionStatistics.edges[0].node;
  return (
    <Layout>
      <HelmetComponent title={title} description={description} image={headerImage.fluid.src} />
      <section id="about-hero" className="hero">
        {/* first <Img> is the purple overlay */}
        <Img
          fluid={headerImage.fluid}
          style={{ position: 'absolute' }}
          className="w-100 bg-img-overlay"
        />
        <Img fluid={headerImage.fluid} style={{ position: 'absolute' }} className="w-100 bg-img" />
        <div className="container page-about-hero">
          <div className="row">
            <div className="col-md-7">
              <h1>{title}</h1>
              <p className="about-intro">{description.description}</p>
            </div>
            <div className="col-md-5 text-center mt60">
              <div className="btn-group about-icon-player">
                <a href="#aboutModal" className="youtube-link" data-toggle="modal">
                  <img className="w-100" src={iconPlayer} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="aboutModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content transparent-modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-container">
              <iframe src="https://www.youtube.com/embed/RDEAQZZp404" title="About" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="primary" className="content-area">
        <main id="main" className="site-main mt-20" role="main">
          <section id="section-whyglory" className="two-columns">
            <div className="container">
              <div className="two-columns-content row mt40">
                <div className="col-md-6 col-sm-12">
                  <h2>{whyHeading}</h2>
                  <p>{whyText.whyText}</p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <Img fluid={whyWeExistImage.fluid} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section id="section-we-help">
            <div className="section-title">
              <h2>{heading}</h2>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h3>{noOfBabies}</h3>
                  <p>{noOfBabiesText}</p>
                </div>
                <div className="col-md-4">
                  <h3>{noOfMoms}</h3>
                  <p>{noOfMomsText}</p>
                </div>
                <div className="col-md-4">
                  <h3>{noOfOutreaches}</h3>
                  <p>{noOfOutreachesText}</p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <h3>{noOfHealthBibleTeachingParticipants}</h3>
                  <p>{noOfHealthBibleTeachingParticipantsText}</p>
                </div>
                <div className="col-md-4">
                  <h3>{noOfPrenatalCheckups}</h3>
                  <p>{noOfPrenatalCheckupsText}</p>
                </div>
                <div className="col-md-4">
                  <h3>{noOfHivScreenings}</h3>
                  <p>{noOfHivScreeningsText}</p>
                </div>
              </div>
            </div>
          </section>

          <section id="section-missionvision" className="two-columns">
            <div className="container">
              <div className="two-columns-content row mt40">
                <div className="col-md-6 col-sm-12">
                  <Img fluid={missionImage.fluid} className="w-100" alt="" />
                </div>
                <div className="col-md-5 col-sm-12">
                  <h2>Our Mission</h2>
                  <p>{mission.mission}</p>
                </div>
              </div>
            </div>
          </section>

          <section id="section-missionvision" className="two-columns">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-md-5 col-sm-12">
                  <h2>{visionHeading}</h2>
                  <p>{vision.vision}</p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <Img fluid={visionImage.fluid} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section id="section-missionvision" className="two-columns d-none d-sm-block">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-md-5 col-sm-12 d-none d-sm-block">
                  <Img fluid={historyImage.fluid} className="w-100" alt="" />
                </div>
                <div className="col-md-6 offset-md-1 col-sm-12 d-none d-sm-block">
                  <h2>{historyHeading}</h2>
                  <p>{history.history}</p>
                </div>
              </div>
            </div>
          </section>

          <section id="section-missionvision" className="two-columns d-block d-sm-none">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-sm-12 d-block d-sm-none">
                  <h2>{historyHeading}</h2>
                  <p>{history.history}</p>
                </div>
                <div className="col-sm-12 d-block d-sm-none">
                  <Img fluid={historyImage.fluid} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section id="section-missionvision" className="two-columns">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-md-6 col-sm-12">
                  <Img fluid={accreditedImage.fluid} className="w-100" alt="" />
                </div>
                <div className="col-md-5 col-sm-12">
                  <h2>{accreditationHeading}</h2>
                  <p>{accreditation.accreditation}</p>
                </div>
              </div>
            </div>
          </section>

          <section id="section-other-ways" className="get-in-touch">
            <div className="section-title">
              <h2>{getInTouchHeading}</h2>
              <p>{getInTouchDesc.getInTouchDesc}</p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="center-horizon">
                    <h3>{addressTitleColumn1}</h3>
                    <p className="purple">
                      <a href={addressMapLink}>{addressMap}</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="center-horizon">
                    <h3>{contactNumbersTitleColumn2}</h3>
                    <p className="purple">
                      <a href={contactNumbersLink[0]}>{contactNumbers[0]}</a> <br />
                      <a href={contactNumbersLink[1]}>{contactNumbers[1]}</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="center-horizon">
                    <h3>{emailTitleColumn3}</h3>
                    <p className="purple">
                      <a href={emailAddLink}>{emailAddress[0]}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
    </Layout>
  );
}

export const aboutPageQuery = graphql`
  query AboutPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    allContentfulPageAbout {
      edges {
        node {
          getInTouchHeading
          getInTouchDesc {
            getInTouchDesc
          }
          addressTitleColumn1
          addressMap
          addressMapLink
          visionHeading
          contactNumbersTitleColumn2
          contactNumbers
          contactNumbersLink
          emailTitleColumn3
          emailAddress
          emailAddLink
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          whyHeading
          whyText {
            whyText
          }
          whyWeExistImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          title
          vision {
            vision
          }
          visionImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          mission {
            mission
          }
          missionImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          historyYoutubeLink
          historyHeading
          historyImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          history {
            history
          }
          accreditedImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          accreditationHeading
          accreditation {
            accreditation
          }
        }
      }
    }
    allContentfulSectionStatistics {
      edges {
        node {
          noOfMoms
          noOfBabiesText
          noOfMomsText
          noOfOutreaches
          noOfOutreachesText
          description {
            description
          }
          heading
          noOfBabies
          noOfHealthBibleTeachingParticipantsText
          noOfHealthBibleTeachingParticipants
          noOfPrenatalCheckupsText
          noOfPrenatalCheckups
          noOfHivScreeningsText
          noOfHivScreenings
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
